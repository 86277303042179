import { InfoOutlined } from '@mui/icons-material';
import { FormHelperText, Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { RawHtmlContent } from '../../components/Format';
import { imagePath } from '../../utils/imgHelper';
import './serviceCatalog.scss';

const Catalog = ({ service, homeCurrency, serviceCount, updateProperty }) => {
  const opacity = service.editable ? '' : 'opacity-50';
  const currencyFlag = `c_flags_svg/${homeCurrency.toLowerCase()}.svg`;
  const hasMinError = (amount, included) => {
    if (!included) {
      return false;
    }
    let minError = false;
    if (service.minAmount != null) {
      minError = amount < service.minAmount;
    }
    return minError;
  };

  const hasMaxError = (amount, included) => {
    if (!included) {
      return false;
    }
    let maxError = false;
    if (service.maxAmount != null) {
      maxError = amount > service.maxAmount;
    }
    return maxError;
  };

  const validateField = (amount, included) => {
    updateProperty(service.id, 'hasMinError', hasMinError(amount, included));
    updateProperty(service.id, 'hasMaxError', hasMaxError(amount, included));
  };

  const helperText = () => {
    if (
      (service.hasMinError || service.hasMaxError) &&
      service.minAmount != null &&
      service.maxAmount != null
    ) {
      return (
        <RawHtmlContent
          htmlContent='JS_errorMsg_minMaxAmount'
          translateValues={({ min: service.minAmount }, { max: service.maxAmount })}
        />
      );
    } else if (service.hasMinError) {
      return (
        <RawHtmlContent
          htmlContent='JS_errorMsg_minAmount'
          translateValues={{ min: service.minAmount }}
        />
      );
    } else if (service.hasMaxError) {
      return (
        <RawHtmlContent
          htmlContent='JS_errorMsg_maxAmount'
          translateValues={{ max: service.maxAmount }}
        />
      );
    }
  };

  return (
    <div>
      <div className='flex items-center justify-between'>
        <p className='text-sm mt-1 mb-1'>
          {service.displayServiceIdentifierFlag ? service.serviceIdentifier + '- ' : ''}{' '}
          {service.serviceName}
          <span>
            {service.displayDescriptionFlag && (
              <Tooltip title={service.description} disableFocusListener>
                <InfoOutlined className='text-accent ml-1' />
              </Tooltip>
            )}
          </span>
        </p>
        {serviceCount > 1 && (
          <FormControlLabel
            control={
              <Checkbox
                sx={{ p: 0 }}
                checked={service.mandatory || service.included || false}
                disabled={service.mandatory}
                className='text-xs'
                onChange={() => {
                  updateProperty(service.id, 'included', !service.included);
                  validateField(service.amountOwing, !service.included);
                }}
              />
            }
            label='Include'
            labelPlacement='start'
            sx={{ mr: 0 }}
          />
        )}
      </div>
      <div className={`flex ${opacity}`}>
        <div className='bg-gray2 rounded rounded-tr-none rounded-br-none px-3 border border-midnight w-[40%] flex items-center'>
          <img
            src={imagePath(currencyFlag)}
            alt='currencyFlag'
            className='w-[24px] h-[24px] rounded-full object-cover'
          />
          <p className='text-14 ml-4'>{homeCurrency}</p>
        </div>
        <TextField
          id='filled-start-adornment'
          className='w-full catalog-field'
          variant='outlined'
          value={service.amountOwing}
          type='number'
          min={0}
          disabled={!service.editable}
          onKeyDown={(e) => {
            if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            let newValue = parseFloat(e.target.value, 10);
            if (isNaN(newValue)) {
              newValue = '';
            }
            if (newValue < 0) {
              newValue = 0;
            }
            updateProperty(service.id, 'amountOwing', newValue);
            validateField(newValue, service.included);
          }}
          error={service.hasMinError || service.hasMaxError}
        />
      </div>
      {(service.hasMinError || service.hasMaxError) && (
        <FormHelperText error>{helperText()}</FormHelperText>
      )}
    </div>
  );
};
export default Catalog;
