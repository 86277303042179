import { apiClient } from '../utils/apiClient';
import { getAPIToken } from './cancelTokens';

export const getToken = (data) => {
  return apiClient.post(
    '/seller/spm/isSpmEnabled',
    {},
    { headers: data, cancelToken: getAPIToken() },
  );
};

export const getRefdata = () => {
  return apiClient.get('/refdataCache', { cancelToken: getAPIToken() });
};

export const getSellerInfo = (sellerId) => {
  return apiClient.get(`/session/create?sellerId=${sellerId}`, { cancelToken: getAPIToken() });
};

export const captureServices = (data) => {
  return apiClient.post('/session/capture/services', data, { cancelToken: getAPIToken() });
};

export const initiatePayment = () => {
  return apiClient.get('/pay/initiatePayment', { cancelToken: getAPIToken() });
};

export const getMoreOptions = () => {
  return apiClient.get('/buyer/pay/moreOptions', { cancelToken: getAPIToken() });
};

export const validateBuyerDetailsAPI = (buyerDetails) => {
  return apiClient.post('/validate', buyerDetails, { cancelToken: getAPIToken() });
};

export const saveBuyerDetails = (buyerDetails) => {
  return apiClient.post('/session/capture/buyer', buyerDetails, { cancelToken: getAPIToken() });
};

export const saveBuyerEmail = (email) => {
  return apiClient.post('/session/capture/email', email, { cancelToken: getAPIToken() });
};

export const pledge = (data) => {
  return apiClient.post('/remitter/pledge', data, { cancelToken: getAPIToken() });
};

export const downloadInstruction = () => {
  return apiClient.get('/buyer/downloadInstruction', {
    responseType: 'arraybuffer',
    cancelToken: getAPIToken(),
  });
};

export const sendInstructionEmail = (data) => {
  return apiClient.post('/paymentInstructionNotification/email ', data, {
    cancelToken: getAPIToken(),
  });
};

export const getAgentDetails = () => {
  return apiClient.get('/agent/info', { cancelToken: getAPIToken() });
};

export const subscribeNotification = (phoneNumber, countryCode, pledgeId) => {
  return apiClient.put(
    `/buyer/notify/sms?pledgeId=${pledgeId}&countryCode=${countryCode}&phoneNumber=${phoneNumber}`,
    null,
    { cancelToken: getAPIToken() },
  );
};

export const syncSession = () => {
  return apiClient.get('/session/state', { cancelToken: getAPIToken() });
};

export const uploadKycDocument = (formData) => {
  return apiClient.post('/kyc/document/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    cancelToken: getAPIToken(),
  });
};

export const deleteKycDocument = (docSeq) => {
  return apiClient.delete(`/kyc/document/${docSeq}`, { cancelToken: getAPIToken() });
};

export const getServiceDetails = (data) => {
  if (data.startsWith('<?xml')) {
    return apiClient.post('/services/sso/request', data, {
      headers: {
        'Content-Type': 'text/plain',
      },
      cancelToken: getAPIToken(),
    });
  } else {
    return apiClient.post('/services/sso/request', JSON.parse(data).paymentInformation, {
      headers: {
        'Content-Type': 'text/plain',
      },
      cancelToken: getAPIToken(),
    });
  }
};

export const captureQuote = (quote) => {
  return apiClient.post('/session/capture/quote', quote, { cancelToken: getAPIToken() });
};

export const getTxnStatus = (statusRequest) => {
  return apiClient.post('/txn/status', statusRequest, { cancelToken: getAPIToken() });
};

export const senDTransactionDisclosure = (tdBody) => {
  return apiClient.post('/services/transactionDisclosure/emailDisclosureStatement', tdBody, {
    cancelToken: getAPIToken(),
  });
};

export const getClientSpecificTranslation = (locale, clientId) => {
  return apiClient.get(`/spI18n/load/${locale}/${clientId}`, { cancelToken: getAPIToken() });
};

export const getWorldpayTxnStatus = (dealReference) => {
  return apiClient.get(`/txn/worldPayStatus?dealReference=${dealReference}`, { cancelToken: getAPIToken() });
};
