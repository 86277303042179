import { ThemeProvider } from '@emotion/react';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import AppRouter from './AppRouter';
import { getServiceDetails, getToken } from './apis';
import I18nDataProvider from './i18n/I18nDataProvider';
import store from './store';
import { initRefdata } from './store/slices/refdata/action';
import {
  ROUTE_MAPPING,
  activateRoutes,
  navigate,
  setRoutes,
  setShowStepper,
} from './store/slices/router/routerSlice';
import {
  setData,
  setDispayParentLogo,
  setHideReceipt,
  setHideServiceCatalog,
  setHomeAmount,
  setHomeCurrency,
  setIsGPXClient,
  setIsPaymentEventEnabled,
  setLocale,
  setSellerId,
  setSsoRequest,
  setToken,
} from './store/slices/session/sessionSlice';
import { injectStore } from './utils/apiClient';
import { getSsoInfo } from './utils/helper';
injectStore(store);

const App = ({ sellerInfo, theme, locale, isPaymentEventEnabled }) => {
  const [_sellerId, _setSellerId] = useState('');
  const [_locale, _setLocale] = useState('');

  useEffect(() => {
    store.dispatch(setIsPaymentEventEnabled(isPaymentEventEnabled));
    // Block this API call in download receipt page, re render app flow - To Do
    if (!store.getState().session.paymentFlowComplete) {
      fetchToken();
    }
  }, []);

  const fetchToken = async () => {
    const { merchantId, merchantSecret, sellerId, reqLocale, buyerId } = getSsoInfo(sellerInfo);
    _setSellerId(sellerId);
    _setLocale(locale ?? reqLocale);
    store.dispatch(setSellerId(sellerId));
    store.dispatch(setSsoRequest(sellerInfo));
    store.dispatch(setLocale(locale ?? reqLocale));
    const data = {
      sellerId: sellerId,
      merchantId: merchantId,
      merchantSecret: merchantSecret,
      buyerId: buyerId,
    };
    const response = await getToken(data);
    if (response.success) {
      const accessResponse = response.data;
      const token = accessResponse.Authorization[0];
      store.dispatch(setToken(token));
      const hideServiceCatalog =
        accessResponse.hideServiceCatalogue &&
        accessResponse.hideServiceCatalogue.length == 1 &&
        accessResponse.hideServiceCatalogue[0] === 'true';
      const hideReceiptPage =
        accessResponse.hideReceiptPage &&
        accessResponse.hideReceiptPage.length == 1 &&
        accessResponse.hideReceiptPage[0] === 'true';

      const dispayParentLogo =
        accessResponse.displayParentLogo &&
        accessResponse.displayParentLogo.length == 1 &&
        accessResponse.displayParentLogo[0] === 'true';

      const serviceResponseData = await getServiceDetails(sellerInfo);
      const serviceResponse = serviceResponseData.data.data;
      if (hideServiceCatalog) {
        store.dispatch(setHideServiceCatalog(true));
        const serviceItems = serviceResponse.serviceItemViewList?.serviceItemViews;
        serviceItems.forEach((s) => {
          s.included = true;
        });
        const isGpx = serviceResponse.sellerInfo.sellerIndustry != 'EDUCATION';
        store.dispatch(setIsGPXClient(isGpx));
        store.dispatch(setHomeAmount(getTotalAmount(serviceItems)));
        store.dispatch(setHomeCurrency(serviceResponse.sellerInfo?.homeCurrency));
      }
      store.dispatch(
        setRoutes({
          hideServiceCatalog: hideServiceCatalog,
          consolBuyerRemitterPageEnabled: serviceResponse.sellerInfo.consolBuyerRemitterPageEnabled,
          hideReceiptPage: hideReceiptPage,
        }),
      );
      /* Navigate once routes set  */
      if (hideServiceCatalog) {
        store.dispatch(navigate({ route: ROUTE_MAPPING.PAYMENT_METHOD }));
      }
      store.dispatch(setData(serviceResponse));
      store.dispatch(setShowStepper(true));
      store.dispatch(setHideReceipt(hideReceiptPage));
      store.dispatch(setDispayParentLogo(dispayParentLogo));
      store.dispatch(initRefdata());
      store.dispatch(activateRoutes(true));
    }
  };

  const getTotalAmount = (serviceItems) =>
    serviceItems.reduce((total, c) => {
      total += c.mandatory || c.included ? parseFloat(c.amountOwing || 0) : 0;
      return total;
    }, 0);

  return (
    <Provider store={store}>
      {_sellerId && _locale && (
        <I18nDataProvider>
          <ThemeProvider theme={theme}>
            <AppRouter />
          </ThemeProvider>
        </I18nDataProvider>
      )}
    </Provider>
  );
};

export default App;
