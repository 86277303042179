import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import ReactDOM from 'react-dom';
import App from './App';
import { clearAllCancelTokens } from './apis/cancelTokens';
import './index.scss';
import store from './store';
import { setLocale } from './store/slices/session/sessionSlice';
import { createThemeForApp } from './style/theme';
import { eventBus } from './utils/events/eventBus';
import { EPS_EVENT } from './utils/events/eventTypes';

class ConveraPaymentElement extends HTMLElement {
  render() {
    if (this.shadowRoot.hasChildNodes()) {
      this.shadowRoot.innerHTML = '';
    }

    console.info('Initialize component');
    //known bug in with font-face. Google fonts are loaded with font-face. font face won't work inside shadow-dom

    const mainHead = document.head;
    mainHead.appendChild(this.createLink('preconnect', 'https://fonts.googleapis.com'));

    mainHead.appendChild(this.createLink('preconnect', 'https://fonts.gstatic.com', true));
    const themeObject = JSON.parse(this.getAttribute('data-theme'));

    const fontUrl =
      themeObject?.fontUrl ||
      'https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;700&display=swap;';

    mainHead.appendChild(this.shadowRoot.appendChild(this.createLink('stylesheet', fontUrl)));

    mainHead.appendChild(
      this.createLink(
        'stylesheet',
        'https://fonts.googleapis.com/css2?family=Noto+Sans+Mono&display=swap',
      ),
    );

    this.shadowRoot.appendChild(
      this.createLink('stylesheet', process.env.REACT_APP_CDN_LINK + 'static/css/main.css'),
    );

    this.shadowRoot.appendChild(
      this.createLink(
        'stylesheet',
        'https://payments.worldpay.com/resources/hpp/integrations/embedded/css/hpp-embedded-integration-library.css',
      ),
    );

    const quantumMetricScript = this.createScript(process.env.REACT_APP_QM_SCRIPT, true, true);
    this.shadowRoot.appendChild(quantumMetricScript);

    const fontName = themeObject?.fontName || '"Noto Sans", sans-serif';
    const primaryColor = themeObject?.primaryColor || '#26E1CF';
    const secondaryColor = themeObject?.secondaryColor || '#001E37';
    const accentColors = themeObject?.accentColors || ['#EEECFF', '#715AF9', '#160E6D'];
    const warningColor = themeObject?.warningColor || '#FFE0BD';
    const primaryButtonColor = themeObject?.primaryButtonColor || '#0A0337';

    this.shadowRoot.appendChild(
      this.createDefaultStyle(fontName, primaryColor, secondaryColor, accentColors, warningColor),
    );
    const mountPoint = document.createElement('div');
    mountPoint.className = 'c-mount-point';
    this.shadowRoot.appendChild(mountPoint);

    const emotionCache = createCache({
      key: 'convera-component',
      prepend: true,
      container: mountPoint,
    });

    const sellerInfo = this.getAttribute('data-seller-info');
    const theme = createThemeForApp(
      mountPoint,
      fontName,
      primaryColor,
      secondaryColor,
      primaryButtonColor,
    );
    const locale = this.getAttribute('data-locale');
    const eventEanbled = this.getAttribute('data-payment-events-enabled');

    ReactDOM.render(
      <CacheProvider value={emotionCache}>
        <App
          sellerInfo={sellerInfo}
          theme={theme}
          locale={locale}
          isPaymentEventEnabled={eventEanbled}
        />
      </CacheProvider>,
      mountPoint,
    );
  }

  createLink(rel, css, crossOrigin = false) {
    const link = document.createElement('link');
    link.rel = rel;
    link.href = css;
    if (crossOrigin) {
      link.crossOrigin = 'anonymous';
    }
    return link;
  }

  createScript(src, defer = false, async = false) {
    const script = document.createElement('script');
    script.src = src;
    script.defer = defer; // Set defer attribute
    script.async = async;
    return script;
  }

  createDefaultStyle(fontName, primaryColor, secondaryColor, accentColors, warningColor) {
    const style = document.createElement('style');
    style.textContent = `
    :root {      
      --custom-font-name: ${fontName};
      --custom-primary-color: ${primaryColor};
      --custom-secondary-color: ${secondaryColor};
      --custom-accent-color-light: ${accentColors[0]};
      --custom-accent-color: ${accentColors[1]};
      --custom-accent-color-dark: ${accentColors[2]};
      --custom-warning-color: ${warningColor};
     }
     
     :host {
        margin: 0;
        font-size: 14px;
        --custom-font-name: ${fontName};
        --custom-primary-color: ${primaryColor};
        --custom-secondary-color: ${secondaryColor};
        --custom-accent-color-light: ${accentColors[0]};
        --custom-accent-color: ${accentColors[1]};
        --custom-accent-color-dark: ${accentColors[2]};
        --custom-warning-color: ${warningColor};
        font-family: var(--custom-font-name);
        text-align: left;
      }
    `;
    return style;
  }

  connectedCallback() {
    /* clear pending request*/
    clearAllCancelTokens();
    /* clear existing state*/
    store.dispatch({ type: 'session/resetState' });
    this.render();
    this.setAttribute('id', 'convera-payment');
    this.listenEvents();
  }

  handleEvent(data, eventType) {
    this.shadowRoot.dispatchEvent(
      new CustomEvent('converaEvent', {
        detail: { ...data, eventType: eventType },
        bubbles: false,
        composed: true, // Allow the event to cross the shadow DOM boundary
      }),
    );
  }

  listenEvents() {
    eventBus.on(EPS_EVENT.REDIRECT, this.handleRedirectEvent);
    eventBus.on(EPS_EVENT.TOKEN_EXPIIRED, this.handleTokenExpiredEvent);
  }

  removeEventListeners() {
    eventBus.off(EPS_EVENT.REDIRECT, this.handleRedirectEvent);
    eventBus.off(EPS_EVENT.TOKEN_EXPIIRED, this.handleTokenExpiredEvent);
  }

  disconnectedCallback() {
    const mountPoint = this.shadowRoot.querySelector('.c-mount-point');
    if (mountPoint) {
      console.debug('Unmount React component');
      const result = ReactDOM.unmountComponentAtNode(mountPoint);
      console.debug(result ? 'sucess' : 'failed');
    }
    this.removeEventListeners();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name == 'data-locale' && oldValue && oldValue != newValue) {
      console.debug('Update locale');
      store.dispatch(setLocale(newValue));
    }
  }

  static get observedAttributes() {
    return ['data-seller-info', 'data-theme', 'data-locale'];
  }

  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
    this.handleRedirectEvent = (data) => this.handleEvent(data, EPS_EVENT.REDIRECT);
    this.handleTokenExpiredEvent = (data) => this.handleEvent(data, EPS_EVENT.TOKEN_EXPIIRED);
  }
}

customElements.define('convera-payment', ConveraPaymentElement);
