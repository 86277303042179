import { FormControl, MenuItem, Select, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectDialCodes } from '../../store/slices/refdata/refdataSlice';
import './telInput.scss';

const TelInput = ({ name, onChange, error, onBlur, disabled, dialCodeName, payerCountry }) => {
  const dialCodes = useSelector(selectDialCodes);
  const [dialCode, selectDialCode] = useState(null);
  const [sortedDialCodes, setSortedDialCodes] = useState([]);
  const [phoneNumber, SetPhoneNumber] = useState('');
  const intl = useIntl();
  useEffect(() => {
    if (payerCountry) {
      let _dialCodes = dialCodes.map((dc) => {
        let _dialCode = { ...dc, name: intl.formatMessage({ id: dc.countryCode }) };
        if (_dialCode.countryCode == payerCountry) {
          selectDialCode(_dialCode);
          onChange(
            {
              target: {
                value: _dialCode,
                name: dialCodeName,
              },
            },
            'countryCode',
          );
        }
        return _dialCode;
      });
      _dialCodes = _dialCodes.sort((c1, c2) => (c1.name > c2.name ? 1 : -1));
      setSortedDialCodes(_dialCodes);
    }
  }, [dialCodes, payerCountry]);

  const handleChange = () => {};

  const handleDialCodeChange = (e) => {
    selectDialCode(e.target.value);
    onChange(e, 'countryCode');
  };

  const handlePhoneNumberChange = (e) => {
    SetPhoneNumber(e.target.value);
    onChange(e);
  };

  return (
    <FormControl fullWidth>
      <div className='flex h-[48px]'>
        <Select
          labelId='demo-controlled-open-select-label'
          id='demo-controlled-open-select'
          className='rounded-tr-none rounded-br-none'
          value={dialCode}
          name={dialCodeName}
          onBlur={handleChange}
          onChange={handleDialCodeChange}
          renderValue={(dialCode) => {
            const flagClassName = `gpfs-flag ${dialCode.countryCode.toLowerCase()}`;
            return (
              <div className=''>
                <i key={dialCode.countryCode} className={flagClassName}></i>
                <span className='ml-3'>+{dialCode.dialingCode}</span>
              </div>
            );
          }}
        >
          {sortedDialCodes.map((option, index) => {
            const flagClassName = `gpfs-flag ${option.countryCode.toLowerCase()}`;
            return (
              <MenuItem key={index} value={option}>
                <i key={option.countryCode} className={flagClassName}></i>
                {option.name}
              </MenuItem>
            );
          })}
        </Select>
        <TextField
          id='filled-start-adornment'
          className='w-full tel-input'
          variant='outlined'
          name={name}
          error={error}
          value={phoneNumber}
          onBlur={onBlur}
          onKeyDown={(e) => {
            if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+') {
              e.preventDefault();
            }
          }}
          onChange={handlePhoneNumberChange}
          disabled={disabled}
        />
      </div>
    </FormControl>
  );
};

export default TelInput;
